<template>
  <portal to="portal_popup">
    <div
      v-if="saving"
      class="z-50 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
    >
      <div>
        <div class="loading-spinner"></div>
        <div class="flex items-center tracking-widest mt-3">Saving ...</div>
      </div>
    </div>
    <modal-window :modal_open="true" title="Create Discount" @close="$emit('close')">
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full">
          <div class="font-medium">Description <span class="text-green-600">*</span></div>
          <input v-model="discount.description" type="text" class="form-control" />
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveDiscount">Save Changes</button>
      </div>
    </modal-window>
  </portal>
</template>

<script>
import validate from 'validate.js';
import { mapActions } from 'vuex';

export default {
  name: 'EditDiscount',
  data() {
    return {
      discount: {
        description: '',
      },
      saving: false,
    };
  },
  methods: {
    ...mapActions({
      staffUpdateDiscountCategories: 'touch/staffUpdateDiscountCategories',
    }),
    async saveDiscount() {
      if (!this.saving) {
        this.saving = true;
        const validationErrors = validate(
          {
            description: this.discount.description,
          },
          {
            description: {
              presence: { allowEmpty: false },
              length: {
                maximum: 255,
              },
            },
          },
        );

        if (validationErrors) {
          this.saving = false;
          this.alertBox().fire({
            title: 'Errors',
            icon: 'error',
            html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
          });
          return;
        }

        await this.staffUpdateDiscountCategories({
          ...this.discount,
        });
        this.saving = false
        this.$emit('saved');
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped></style>
