<template>
  <div class="mt-5">
    <div class="flex flex-row overflow-x-scroll">
      <div
        v-for="(item, index) in articles"
        :key="item.id"
        class="flex flex-col flex-shrink-0 w-2/4 sm:w-1/3 bg-white rounded-lg overflow-hidden"
        :class="{ 'mr-5': index !== articles.length - 1 }"
        :style="{ 'max-width': '300px' }"
      >
        <div class="p-4 mb-auto">
          <div class="mb-5">
            <div class="flex items-center">
              <img class="mr-1 w-5 h-5" src="/images/knowledge-base-logo.png" />
              <span class="text-xs">Knowledge Base</span>
              <i class="cursor-pointer ml-auto fal fa-times" @click.stop="Id = item.id"></i>
            </div>
          </div>
          <a :href="item.url" target="_blank">
            <div v-if="item.duration">
              <span class="text-xs">{{ item.duration }}</span>
            </div>
            <div class="mb-3">
              <span class="text-lg">{{ item.title }}</span>
            </div>
          </a>
        </div>
        <a class="cursor-pointer" :href="item.url" target="_blank">
          <img :src="'/images/knowledge-base/' + item.imageURL" class="w-100" />
        </a>
      </div>
    </div>
    <portal v-if="Id" to="portal_popup">
      <modal-window :modal_open="true" title="Hide Article" @close="Id = undefined">
        <div>
          Please visit
          <router-link class="underline text-blue-500" to="/my-account">My Account</router-link>
          <i class="fa fa-arrow-right mx-2" aria-hidden="true"></i>
          <router-link
            class="underline text-blue-500"
            to="/my-account/hidden-articles"
          >
            Hidden Articles
          </router-link>
          to undo this change.
        </div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="Id = undefined">
            Cancel
          </button>
          <button
            class="btn-action btn-lg"
            @click.prevent.stop="addHiddenArticlesIDs(Id), (Id = undefined)"
          >
            Hide Article
          </button>
        </div>
      </modal-window>
    </portal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      Id: undefined,
    };
  },
  computed: {
    articles() {
      const { articles, hiddenArticlesIDs } = this.$store.state.knowledgeBase;
      if (['Selecta Systems Ltd', 'Conservatory Outlet', 'DoorCo'].includes(this.$store.state.style.fabricator.name)) {
        return articles.filter((x) => !hiddenArticlesIDs.concat('5').includes(x.id));
      }
      return articles.filter((x) => !hiddenArticlesIDs.includes(x.id));
    },
  },
  methods: {
    ...mapMutations('knowledgeBase', {
      addHiddenArticlesIDs: 'addHiddenArticlesIDs',
    }),
  },
};
</script>
