<template>
  <div class="w-full">
    <div class="bg-white overflow-x-auto pl-3 md:pl-10">
      <div class="flex bg-white gap-10 border-t-4 border-white">
        <router-link
          to="/product-setup/product-selections"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'product-selections',
            'border-white': $route.name !== 'product-selections',
          }"
        >
          Product Selections
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/pricing"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'pricing',
            'border-white': $route.name !== 'pricing',
          }"
        >
          Pricing
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/parts"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'parts',
            'border-white': $route.name !== 'parts',
          }"
        >
          Stock Parts
        </router-link>
        <router-link
          v-if="!isLeadGen && $store.state.auth.superuser"
          to="/product-setup/touch-connect-business"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'touch-connect-business',
            'border-white': $route.name !== 'touch-connect-business',
          }"
        >
          Touch Connect
        </router-link>
        <router-link
          v-if="!isLeadGen && is_admin_user"
          to="/product-setup/vouchers"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 gap-5 hover:border-black"
          :class="{
            'border-black': $route.name === 'vouchers',
            'border-white': $route.name !== 'vouchers',
          }"
        >
          Vouchers
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/discounts"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{
            'border-black': $route.name === 'discounts',
            'border-white': $route.name !== 'discounts',
          }"
        >
          Discounts
        </router-link>
        <router-link
          v-if="!isLeadGen && !$store.state.auth.accountSettings.hideFitting"
          to="/product-setup/extras"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black"
          :class="{'border-black' : $route.name === 'extras', 'border-white' : $route.name !== 'extras'}"
        >
          Extras
        </router-link>
        <router-link
          v-if="!isLeadGen"
          to="/product-setup/option-lead-times"
          class="flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black text-sm"
          :class="{
            'border-black': $route.name === 'option-lead-times',
            'border-white': $route.name !== 'option-lead-times',
          }"
        >
          Option Lead Times
        </router-link>
        <div class="pr-5"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    isLeadGen() {
      return (
        this.$store.state.style?.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
  },
};

</script>
