<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Registration Requests'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Registration Requests
      </div>
    </template>
    <slot></slot>
    <loading :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-5">
      <div class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full bg-white p-5">
          <thead>
            <tr>
              <th> # </th>
              <th> Company </th>
              <th> Email</th>
              <th> Website </th>
              <th> Phone </th>
              <th>
                Verification
                <span class="cursor-pointer inline-block relative">
                  <span
                    class="inline-block bg-gray-400 rounded p-1 text-white"
                    @click="verification_dropdown = !verification_dropdown"
                  >
                    <i class="fal fa-filter mx-1"></i>
                    <span v-if="$route.query.verification == enums.applicationStatus.PENDING">
                      Pending
                    </span>
                    <span v-else-if="$route.query.verification == enums.applicationStatus.VERIFIED">
                      Verified
                    </span>
                    <span v-else-if="$route.query.verification == enums.applicationStatus.ACCEPTED">
                      Accepted
                    </span>
                    <span v-else-if="$route.query.verification == enums.applicationStatus.DECLINED">
                      Declined
                    </span>
                    <i v-if="verification_dropdown" class="fal fa-chevron-up mx-1"></i>
                    <i v-else class="fal fa-chevron-down mx-1"></i>
                  </span>
                  <div v-show="verification_dropdown" class="relative">
                    <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded right-0 mt-1" style="min-width: 200px;">
                      <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                        <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Verification</div>
                        <div class="flex flex-col justify-around">
                          <i class="fal fa-times" @click.stop="verification_dropdown = false"></i>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div
                          v-for="status in applicationStatus"
                          :key="status.id"
                          class="py-2 px-4 hover:bg-gray-200"
                          @click="toggleFilter(status[1])"
                        >
                          <span>
                            <span
                              :class="{
                                'bg-blue-500': status[1] === enums.applicationStatus.PENDING,
                                'bg-green-800': status[1] === enums.applicationStatus.VERIFIED,
                                'bg-green-500': status[1] === enums.applicationStatus.ACCEPTED,
                                'bg-red-500': status[1] === enums.applicationStatus.DECLINED
                              }"
                              class="inline-block rounded-full w-2 h-2 mr-2"
                            ></span>
                            {{ status[0] }}
                          </span>
                          <span v-show="$route.query.verification == status[1]" class="text-green-600 pl-2">
                            <i class="fal fa-check"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in customers"
              :key="customer.id"
            >
              <td>
                <div class="td-title w-24"> #: </div>
                <div class="td-content">{{ customer.id }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Company: </div>
                <div class="td-content">{{ customer.companyName }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Email: </div>
                <div class="td-content">{{ customer.email }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Website: </div>
                <div class="td-content">{{ customer.website }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Phone: </div>
                <div class="td-content">{{ customer.phone }}</div>
              </td>
              <td>
                <div class="td-title w-24"> Verification: </div>
                <div class="capitalize">{{ applicationStatus[customer.status][0].toLowerCase() }}</div>
              </td>
              <td>
                <div v-if="customer.status === enums.applicationStatus.VERIFIED" class="grid lg:grid-cols-2 gap-2">
                  <div
                    class="bg-red-500 rounded text-white px-10 py-1 cursor-pointer text-center"
                    @click="processTradeAccountApplication(customer.id, enums.applicationStatus.DECLINED)"
                  >
                    Decline
                  </div>
                  <div
                    class="bg-green-500 rounded text-white px-10 py-1 cursor-pointer text-center"
                    @click="processTradeAccountApplication(customer.id, enums.applicationStatus.ACCEPTED)"
                  >
                    Accept
                  </div>
                </div>
                <div
                  v-else-if="customer.status === enums.applicationStatus.PENDING"
                >
                  <div
                    v-if="resentLoadingId === customer.id"
                    class="bg-blue-500 rounded text-white px-10 py-1 text-center"
                  >
                    Sending
                    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
                  </div>
                  <div
                    v-else-if="resentIds.includes(customer.id)"
                    class="bg-blue-300 rounded text-white px-10 py-1 text-center"
                  >
                    Email Sent
                  </div>
                  <div
                    v-else
                    class="bg-blue-500 rounded text-white px-10 py-1 cursor-pointer text-center w-full"
                    @click="resendVerificationEmail(customer.id)"
                  >
                    Resend Email
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
          <div class="flex justify-between">
            <div>
              No requests to show
            </div>
            <div v-if="$route.query.search || $route.query.verification">
              <a class="btn-action" @click.stop="setDefaultFilter()">
                Clear Search
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customers.length > 0" class="mt-3">
        <pagination
          :key="JSON.stringify($route.query)"
          :total_items="total_items"
          :limit="limit"
          @load-feed="loadFeed"
        ></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>
import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
  },
  data() {
    return {
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      resentIds: [],
      resentLoadingId: undefined,
      verification_dropdown: false
    };
  },
  computed: {
    applicationStatus() {
      return Object.keys(this.enums.applicationStatus).map((key) => [key, this.enums.applicationStatus[key]]);
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.verification_dropdown = false;
        this.loadFeed();
      },
      immediate: true
    },
  },
  methods: {
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await window.touch.ListTradeAccountApplications({
        Limit: this.limit,
        Offset: offset,
        Status: this.$route.query.verification ? parseInt(this.$route.query.verification) : undefined
      });
      this.customers = response.applications;
      this.total_items = response.totalApplications;
      this.loading = false;
    },
    async processTradeAccountApplication (id, status) {
      await window.touch.ProcessTradeAccountApplication(id, status)
      await this.loadFeed()
    },
    async resendVerificationEmail (id) {
      this.resentLoadingId = id
      await window.touch.ResendVerificationEmail(id)
      this.resentIds.push(id)
      this.resentLoadingId = undefined
    },
    toggleFilter (verification) {
      this.$router.push({ query: { ...this.$route.query, verification: this.$route.query.verification == verification ? undefined : verification } }).catch(() => {});
      this.verification_dropdown = false
    },
    setDefaultFilter() {
      this.$router.replace({ query: undefined }).catch(() => {});
    },
  }
};
</script>

<style scoped>

.dot {
  animation: dot linear 0.9s infinite;
}
.dot:nth-of-type(2) {
  animation-delay: 0.3s;
}
.dot:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0% { opacity: 0.25; }
  100% {opacity: 1; }
}

</style>