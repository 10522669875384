<template>
  <Checkout
    ref="checkout"
    breakdown="Cost"
    @cancelOrder="cancelOrder"
    @redirectProcessPlaceOrder="redirectProcessPlaceOrder"
  >
    <template #order-review>
      <div
        v-if="$refs.checkout && !$refs.checkout.editing_delivery_address"
        key="2"
        class="bg-white shadow-lg rounded-lg p-6 xs:p-10 mt-6 xs:mt-10 mb-10 xs:mb-0"
        data-index="2"
      >
        <div class="border-b border-gray-200 pb-3 mb-3 text-lg">
          <i class="fal fa-check text-green-600 mr-3 hidden 2xs:inline"></i> 3. Order Review
        </div>
        <div>
          <div class="flex flex-no-wrap sm:p-6 pt-6 pb-0">
            <label>
              <input v-model="$refs.checkout.terms_checked" type="checkbox" />
            </label>
            <div class="pl-6">
              Before ticking this box, please
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.privacyPolicyDocumentUrl"
                target="_blank"
              >
                Click here
              </a>
              for more information about how we will use your personal information. By ticking this
              box you confirm that you understand and agree to our
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.privacyPolicyDocumentUrl"
                target="_blank"
              >
                privacy policy
              </a>
              and
              <a
                class="text-green-600 hover:text-green-800 cursor-pointer"
                :href="$refs.checkout.termsDocumentUrl"
                target="_blank"
              >
                terms & conditions.
              </a>
            </div>
          </div>
          <div id="show_terms">
            <div v-if="$refs.checkout.show_terms_error" class="text-red-600">
              You must tick this box to continue.
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="isOrder && !isManualPricing" #voucher="{ type, itemKey, override }">
      <VoucherCode 
        :class="{'text-center': type === 'order', 'max-w-sm': type === 'item'}"
        :type="type"
        :item-key="itemKey"
        :is-applied="override && $store.state.basket?.job?.discountApplied"
      ></VoucherCode>
    </template>
  </Checkout>
</template>

<script>
import Checkout from '@/components/shared/checkout/Checkout.vue';
import VoucherCode from '@/components/shared/basket/VoucherCode.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Checkout,
    VoucherCode
  },
  computed: {
    ...mapState('basket', {
      buttonName: 'buttonName',
      basketItems: 'basket',
      isOrder: 'isOrder',
      job: 'job'
    }),
    isManualPricing () {
      return (this.job?.overrideCalculatedPrice || this.basketItems?.some(x => x.override)) && !this.job?.discountApplied
    }
  },
  mounted() {
    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush('/new-quote');
    }
  },
  methods: {
    cancelOrder() {
      this.$store.dispatch('basket/clearBasket');
      this.$router.go(-1);
    },
    redirectProcessPlaceOrder({ isSuccess }) {
      if (isSuccess) {
        this.routerPush('/orders');
      } else {
        this.routerPush('/orders');
      }
    },
  },
};
</script>
