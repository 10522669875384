<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Touch Store']]" />
    </template>
    <template #header_page_title> Branded Mode </template>
    <template #header_page_actions>
      <LiveChatButton ref="liveChat" class="mr-4" />
    </template>
    <main class="p-5 overflow-x-scroll pb-10 bg-gray-200 h-full">
      <div class="text-center">
        <div class="mb-1">
          <i class="text-2xl fa-light fa-comments"></i>
        </div>
        <div class="my-1">
          <span class="text-2xl">Got a question about these products?</span>
        </div>
        <div class="my-1">
          <span class="text-lg text-green-500 font-thin cursor-pointer" @click="$refs?.liveChat?.showLiveChat()">Click Here to Live Chat</span>
        </div>
      </div>
      <div v-if="!branded_mode_enabled" class="border-solid border border-gray-200 mt-10 rounded-lg overflow-hidden bg-white">
        <div class="px-10 py-5 mx-auto" style="max-width: 1800px;">
          <div class="xs:flex py-3 items-center mb-5 text-center sm:text-start">
            <span class="text-xl">
              <span class="font-medium">Branded Mode</span>  - £45 + VAT <span class="text-sm">per month</span>
            </span>
          </div>
          <div class="grid xs:grid-cols-2 lg:grid-cols-3 gap-5 mb-10">
            <div class="grid lg:grid-cols-2 lg:col-span-2 gap-5">
              <div class="max-w-lg">
                <img src="/images/branded mode.jpg" />
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-lg font-medium">Description</span>
                <span>
                  Generate enquiries online with Connect Window & Door Designer.
                </span>
                <span>
                  For use on your website, in your showroom, a customer's home or social media.
                </span>
                <span>
                  Free for 1 month.
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-lg font-medium">Features</span>
              <div class="grid">
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Lead Generation Software</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Use online, in showroom or customers home</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Add prices to quotes</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Quote management</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Home Visualiser</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Personalised Paperwork</span>
                </div>
                <div class="flex rounded-lg gap-2">
                  <i class="text-2xl fal fa-square-check align-middle"></i>
                  <span class="text-sm">Accurate Product Imagery</span>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 xs:flex gap-2">
            <router-link
              to="/touch-store/more-benefits"
              class="xs:ml-auto btn btn-md"
            >
              Discover More
            </router-link>
            <a
              v-if="$store.state.style.fabricator.name === 'Selecta Systems Ltd'"
              href="https://selecta.portal.bm-touch.co.uk/launch/selecta-systems-demo"
              target="_blank" 
              class="btn btn-md"
            >
              View Demo
            </a>
            <button
              class="btn-action btn-md xs:w-64 col-span-2"
              @click="$refs.redirect.redirect()"
            >
              Buy Branded Mode
            </button>
          </div>
        </div>
      </div>
      <div v-if="$store.state.style.fabricator.name === 'Selecta Systems Ltd'" class="flex flex-col xs:grid grid-cols-2 gap-3 mt-3 text-center">
        <div class="py-5 rounded-lg bg-white flex flex-col gap-2">
          <div class="p-5 flex flex-col flex-grow  gap-5 max-w-md mx-auto">
            <div>
              <strong class="text-lg">Consumer Website Theme</strong>
              <div><span class="text-lg">£1500 + VAT </span> one off cost</div>
            </div>
            <img src="/images/selecta/consumer-website-theme.jpg" />
            <div>
              <div>A prebuilt Selecta Systems website theme perfectly tailored for the consumer market. Featuring a wide range of fully populated Advanced 70 product pages. Additional upgrades are available.</div>
            </div>
          </div>
          <hr class="opacity-25">
          <div class="w-full">
            <div class="p-5 grid grid-cols-2 gap-2 max-w-md mx-auto">
              <a
                :href="store_url + '/websites/selecta-for-installers'"
                target="_blank"
                class="btn btn-md"
              >
                Discover More
              </a>
              <a
                v-if="$store.state.style.fabricator.name === 'Selecta Systems Ltd'"
                href="https://selecta-for-installers.bm-touch.com/"
                target="_blank" 
                class="btn btn-md"
              >
                View Demo
              </a>
              <a
                class="btn-action btn-md col-span-2"
                :href="store_url + '/website-enquiry'"
                target="_blank" 
              >
                Buy / Enquire Now
              </a>
              <div class="flex gap-2 col-span-2 items-center mx-auto mt-2">
                <span>In partnership with</span>
                <img class="w-12" src="/images/consultancy-logo.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div class="py-5 rounded-lg bg-white flex flex-col gap-2">
          <div class="p-5 flex flex-col flex-grow gap-5 max-w-md mx-auto">
            <div>
              <strong class="text-lg">Trade Website Theme</strong>
              <div><span class="text-lg">£1500 + VAT</span> one off cost</div>
            </div>
            <img src="/images/selecta/trade-website-theme.jpg" />
            <div>
              <div>Target the trade and installer market using an informative and user friendly Selecta Systems website theme. Featuring a wide range of fully populated Advanced 70 product pages. Additional upgrades are available.</div>
            </div>
          </div>
          <hr class="opacity-25">
          <div class="w-full">
            <div class="p-5 grid grid-cols-2 gap-2 max-w-md mx-auto">
              <a
                :href="store_url + '/websites/selecta-for-fabricators'"
                target="_blank"
                class="btn btn-md"
              >
                Discover More
              </a>
              <a
                v-if="$store.state.style.fabricator.name === 'Selecta Systems Ltd'"
                href="https://selecta-for-fabricators.bm-touch.com/"
                target="_blank" 
                class="btn btn-md"
              >
                View Demo
              </a>
              <a
                class="btn-action btn-md col-span-2"
                :href="store_url + '/website-enquiry'"
                target="_blank" 
              >
                Buy / Enquire Now
              </a>
              <div class="flex gap-2 col-span-2 items-center mx-auto mt-2">
                <span>In partnership with</span>
                <img class="w-12" src="/images/consultancy-logo.jpg" />
              </div>
            </div>
          </div>
        </div>        
        <div class="py-5 rounded-lg bg-white flex flex-col gap-2">
          <div class="p-5 flex flex-col flex-grow  gap-5 max-w-md mx-auto">
            <div>
              <strong class="text-lg">Bespoke Website</strong>
              <div>£POA</div>
            </div>
            <img src="/images/selecta/bespoke-website.jpg" />
            <div>
              <div>Tailored to your unique needs, our custom designs ensure your brand stands out, providing a seamless user experience that drives engagement and growth.</div>
            </div>
          </div>
          <hr class="opacity-25">
          <div class="w-full">
            <div class="p-5 grid grid-cols-2 gap-2 max-w-md mx-auto">
              <a
                :href="store_url + '/websites/selecta-bespoke-websites'"
                target="_blank"
                class="btn btn-md"
              >
                Discover More
              </a>
              <a
                v-if="$store.state.style.fabricator.name === 'Selecta Systems Ltd'"
                :href="store_url + '/case-studies'"
                target="_blank" 
                class="btn btn-md"
              >
                View Portfolio
              </a>
              <a
                class="btn-action btn-md col-span-2"
                :href="store_url + '/bespoke-website-enquiry'"
                target="_blank" 
              >
                Enquire Now
              </a>
              <div class="flex gap-2 col-span-2 items-center mx-auto mt-2">
                <span>In partnership with</span>
                <img class="w-12" src="/images/consultancy-logo.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Redirect ref="redirect" />
    </main>
  </touch-layout>
</template>

<script>
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import Redirect from '@/components/branded/touchStore/Redirect.vue';
import LiveChatButton from '@/components/shared/nav/LiveChatButton.vue';

export default {
  components: {
    Breadcrumbs,
    Redirect,
    LiveChatButton,
  },
  destroyed() {
    window.LiveChatWidget.off('ready', this.readyCallback);
    window.LiveChatWidget.off('availability_changed', this.availabilityChangedCallback);
  },
};
</script>
