<template>
  <div
    v-if="invalidLink"
    class="absolute top-0 left-0 right-0 bottom-0 bg-white flex flex-col justify-around text-center"
  >
    <div>
      <h1 class="text-3xl">Designer Unavailable.</h1>
      <ul>
        <li>Invalid Link</li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div
      v-if="demo_mode"
      ref="intro_popup"
      class="absolute top-0 left-0 right-0 bottom-0 bg-black_80 flex flex-col justify-around z-10"
    >
      <div
        class="p-10 m-auto text-center text-white rounded-lg bg-touch-purple"
        style="max-width: 500px"
      >
        <div class="pb-6">
          Welcome to the website designer, this will be accessed by your customers via your website.
        </div>
        <div>
          The setup allows you to brand it with your logo, turn live pricing on or off and gives
          your customer the choice to supply measurements or not depending on their capability to
          measure.
        </div>
        <div class="pt-10">
          <div
            class="border border-white p-3 hover:bg-white hover:text-touch-purple rounded cursor-pointer"
            @click="$refs.intro_popup.remove()"
          >
            Continue <i class="fal fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
    <transition v-if="loading" name="fade-in">
      <div
        class="z-50 bg-white absolute bottom-0 left-0 right-0 top-0 p-10 flex flex-col justify-around items-center"
      >
        <div>
          <div class="loading-spinner"></div>
          <div class="flex items-center tracking-widest mt-3">Loading</div>
        </div>
      </div>
    </transition>
    <touch-layout v-else-if="!loading && !hasErrors">
      <div
        class="flex sm:gap-10 sm:p-10 justify-around w-full text-center sm:text-left flex-row scrolling-touch flex-col-reverse sm:flex-row flex-grow sm:mx-auto"
        style="max-width: 1400px"
      >
        <div class="flex flex-col justify-around">
          <div style="max-width: 500px; max-height: 100%" class="p-4 mx-auto">
            <div v-if="customHeader" class="text-3xl xs:text-5xl leading-tight">
              <strong>{{ customHeader }}</strong>
            </div>
            <div v-else v-html="header"></div>
            <div v-if="customHeader && subHeader" class="text-xl leading-tight mt-6">
              <strong>{{ subHeader }}</strong>
            </div>
            <div v-if="customHeader && welcomeText" class="mt-6" v-html="welcomeText"></div>
            <template v-if="shouldAskForMeasurements">
              <div class="font-medium sm:text-lg mt-10">Do you have measurements?</div>
              <div class="mt-4">
                If you have measurements and know the size of your required windows and/or doors
                click <em class="text-gray-600">'I have measurements'</em>
              </div>
              <div class="mt-4">
                If you are just looking to design and make an enquiry click
                <em class="text-gray-600">'I don't have measurements'</em>
              </div>
              <div class="mx-auto sm:mx-0 mt-10" style="max-width: 320px">
                <button
                  v-if="ready"
                  class="btn-lg w-full btn-dark flex"
                  @click="goWithMeasurements()"
                >
                  <div class="text-center flex-grow">I have measurements</div>
                  <div class="flex flex-col justify-around ml-auto -mr-3 p-1">
                    <i class="fal fa-tape fa-lg"></i>
                  </div>
                </button>
                <div class="my-3 text-center">or</div>
                <button
                  v-if="ready"
                  class="btn-action btn-lg w-full flex"
                  @click="goWithoutMeasurements()"
                >
                  <div class="text-center flex-grow">I don't have measurements</div>
                  <div class="flex flex-col justify-around ml-auto -mr-3 p-1">
                    <i class="fal fa-times-square fa-lg"></i>
                  </div>
                </button>
              </div>
            </template>
            <template v-else>
              <button
                v-if="ready"
                class="btn-action btn-lg w-full flex mt-6"
                @click="goWithoutMeasurements()"
                v-html="$t('branded-mode-go-without-measurements-button')"
              ></button>
            </template>
            <div v-if="isDealerNetworkMode && !['Selecta Systems Ltd', 'DoorCo', 'Conservatory Outlet'].includes($store.state.style.fabricator.name)" class="pt-5">
              <span>Trade Customer? </span>
              <router-link :to="'/login'" class="inline-block text-center text-green-600 hover:underline">
                Click here for Quote & Ordering
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="hasCustomBackground || defaultPath" class="sm:flex-1 flex flex-col sm:justify-center p-5 xs:w-8/12 sm:w-full mx-auto">
          <img class="rounded-lg" :src="hasCustomBackground ? customUrl : defaultPath" />
        </div>
      </div>
    </touch-layout>
    <div
      v-else
      class="absolute top-0 left-0 right-0 bottom-0 bg-white flex flex-col justify-around text-center"
    >
      <div>
        <h1 class="text-3xl">Designer Unavailable.</h1>
        <ul>
          <li v-for="error in designer_errors" :key="error">{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

export default {
  name: 'StartPublicSession',
  data() {
    return {
      loading: true,
      image_number: 1,
      designer_errors: null,
      invalidLink: false,
      companySettings: [],
      customUrl: '',
      header: '',
      customHeader: '',
      subHeader: '',
      welcomeText: '',
      defaultPath: undefined,
      initialCustomFetchPerformed: false,
    };
  },
  computed: {
    ...mapGetters({
      chooseProductURL: 'branded/chooseProductURL',
      ready: 'branded/ready',
    }),
    ...mapState('auth', {
      isDealerNetworkMode: 'isDealerNetworkMode',
    }),
    shouldAskForMeasurements() {
      return this.companySettings.showConsumerSizes === true;
    },
    hasCustomBackground() {
      return (
        typeof this.customUrl !== 'undefined' &&
        this.customUrl !== null &&
        this.customUrl.length > 0
      );
    },
    hasPricing() {
      return !this.branded_mode_user || this.$store.getters['user/hasBrandedPricingOn'];
    },
    hasErrors() {
      return this.designer_errors !== null && this.designer_errors.length > 0;
    },
  },
  async mounted() {
    const { slug } = this.$route.params;
    try {
      await this.$store.dispatch('touch/startPublicSession', {
        slug,
        returnUrl: 'http://www.google.com',
      });
    } catch (e) {
      this.invalidLink = true;
      return;
    }
    this.$store.dispatch('visualiser/clear');
    this.designer_errors = await this.isDesignerAvailable(slug);
    this.$store.dispatch('style/loadCustomerStyle');
    if (this.$route.query.more === 'Y') {
      if (this.$store.state.branded.isMeasurementsOn) {
        this.setMeasurements(true);
      } else {
        this.setMeasurements(false);
      }
      this.routerPush(this.chooseProductURL);
    }
    this.companySettings = (await this.getCompanySettings()).companySettings;
    if (this.companySettings === null) {
      this.companySettings = {};
    }
    
    this.setShowDesignNames(this.companySettings?.showDesignNames)

    // eslint-disable-next-line no-nested-ternary
    this.mode = this.isDealerNetworkMode ? 'dealer' : 'branded'
    this.defaultPath = this.$t('/images/' + this.mode + '-intro') + '-1.png'
    const customStyles = await this.getCompanyStyles()
    this.customUrl = customStyles?.enquiryPageStyling?.welcomeImageUri;
    await this.setText(customStyles);

    this.loading = false;

    this.header = this.$t(
      `start-${this.mode}-mode-header`,
      "div class='text-3xl xs:text-5xl leading-none'><strong>Find the perfect products to transform your home.</strong></div>",
    );
    setTimeout(() => {
      this.changeImage();
    }, 3000);
  },
  methods: {
    ...mapActions({
      isDesignerAvailable: 'auth/isDesignerAvailable',
      getCompanySettings: 'touch/customerGetCompanySettings',
      getCompanyStyles: 'style/getCompanyStyles',
    }),
    ...mapMutations('branded', {
      setShowDesignNames: 'setShowDesignNames',
    }),
    async setMeasurements(measurementsOn) {
      await this.$store.dispatch('basket/setMeasurements', measurementsOn);
      await this.$store.dispatch('branded/setIsMeasurementsOn', measurementsOn);
    },
    goWithMeasurements() {
      this.setMeasurements(true);
      this.routerPush(this.chooseProductURL);
    },
    goWithoutMeasurements() {
      this.setMeasurements(false);
      this.routerPush(this.chooseProductURL);
    },
    async setText(customStyles) {
      this.customHeader = customStyles?.enquiryPageStyling?.welcomeHeadingText ?? this.customHeader;
      this.subHeader = customStyles?.enquiryPageStyling?.welcomeSubHeadingText ?? this.subHeader;
      this.welcomeText =customStyles?.enquiryPageStyling?.welcomeText ?? this.welcomeText;
    },
    async changeImage() {
      if (this.hasCustomBackground) {
        return;
      }
      if (!this.is_iOS && window.innerWidth > 1024 && !this.hasCustomBackground) {
        const mode = this.isDealerNetworkMode ? 'dealer' : 'branded';
        const maxImageNumber = Number(this.$t(`${mode}-intro-image-number`));
        const imagePath = this.$t(`/images/${mode}-intro`);

        if (this.image_number === maxImageNumber) {
          this.image_number = 1;
        } else {
          this.image_number += 1;
        }
        
        this.defaultPath = `${imagePath}-${this.image_number}.png`;
        setTimeout(() => {
          this.changeImage();
        }, 3000);
      }
    },
  },
};
</script>
