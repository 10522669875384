import * as Sentry from '@sentry/browser';
import store from '@/store';
import router from '@/router';
import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.processingBasketSummary = async function processingBasketSummary(
  CustomerId,
  ContractId,
  JobKey,
  ProcessingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/BasketSummary'),
      {
        CustomerId,
        ContractId,
        JobKey,
        ProcessingLevel,
      },
    )
  )
};

Touch.prototype.processingAddStockItem = async function processingAddStockItem(
  ContractId,
  JobKey,
  ItemId,
  Quantity,
  LinkToItemKey,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/AddStockItem'),
      {
        ContractId,
        JobKey,
        ItemId,
        LinkToItemKey,
        Quantity,
      },
    )
  ).data;
};

Touch.prototype.processingContract = async function processingContract(contractId) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/Contract'),
      {
        ContractId: contractId,
      },
    ),
    errorCallback,
  );
};

Touch.prototype.processingSetVisualisations = async function processingSetVisualisations(
  contractId,
  jobKey,
  visualisations,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/SetVisualisations'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        Visualisations: visualisations,
      },
    )
  ).data;
};

Touch.prototype.processingGetAvailableProductsToSelect =
  async function processingGetAvailableProductsToSelect(typeId, groupId, customerId) {
    return (
      await this.authenticatedPost(
        await store.dispatch('sandbox/processingUrl', 'processing/GetAvailableProductsToSelect'),
        {
          TypeId: typeId,
          GroupId: groupId,
          CustomerId: customerId,
        },
      )
    ).data;
  };

Touch.prototype.processingGetAvailableProductSelectionGroups =
  async function processingGetAvailableProductSelectionGroups(customerId) {
    return this.parseResponse(
      await this.authenticatedPost(
        await store.dispatch(
          'sandbox/processingUrl',
          'processing/GetAvailableProductSelectionGroups',
        ),
        {
          CustomerId: customerId,
        },
      ),
      errorCallback,
    );
  };

Touch.prototype.processingGetAvailableProductSelectionTypes =
  async function processingGetAvailableProductSelectionTypes(customerId, groupId) {
    return (
      await this.authenticatedPost(
        await store.dispatch(
          'sandbox/processingUrl',
          'processing/GetAvailableProductSelectionTypes',
        ),
        {
          GroupId: groupId,
          CustomerId: customerId,
        },
      )
    ).data;
  };

Touch.prototype.getProducts = async function getProducts() {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/Products'),
    )
  ).data;
};

Touch.prototype.processingGetCustomerPriceBreakdown =
  async function processingGetCustomerPriceBreakdown(contractId, jobId, itemId) {
    return (
      await this.authenticatedPost(
        await store.dispatch('sandbox/processingUrl', 'processing/GetCustomerPriceBreakdown'),
        {
          ContractId: contractId,
          JobKey: jobId,
          ItemKey: itemId,
        },
      )
    ).data;
  };

Touch.prototype.productStoredDesigns = async function productStoredDesigns(
  customerId,
  processingLevel,
  productId,
  width = undefined,
  height = undefined,
  bayBowOnly = false,
  limit = 1000000,
  offset = 0,
  squaresQty = undefined,
  coupledOnly = false,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/ProductStoredDesigns'),
      {
        ProductId: productId,
        OnlyShowBayBowCompatible: bayBowOnly,
        OnlyShowCoupledCompatible: coupledOnly,
        TargetWidth: width ? Number(width) : undefined,
        TargetHeight: height ? Number(height) : undefined,
        ProcessingLevel: processingLevel,
        Limit: limit,
        Offset: offset,
        QtySquares: squaresQty,
        CustomerId: customerId,
      },
    ),
    [],
  );
};

Touch.prototype.processingGetProcessingLevel = async function processingGetProcessingLevel() {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/GetProcessingLevel'),
    )
  ).data.processingLevelId;
};

Touch.prototype.createJobLineItem = async function createJobLineItem(
  customerId,
  contractId,
  jobKey,
  designId,
  productId,
  processingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/CreateJobLineItem'),
      {
        ContractId: contractId,
        CustomerId: customerId,
        JobKey: jobKey,
        ProductStoredDesignId: designId,
        ProductId: productId,
        ProcessingLevel: processingLevel,
      },
    ),
  );
};

Touch.prototype.updateJobLineItemDimension = async function updateJobLineItemDimension(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  dimensionId,
  value,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineItemDimension'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        DimensionId: dimensionId,
        Value: parseInt(value, 10),
      },
    ),
    errorCallback,
  );
};

Touch.prototype.updateJobLineItemQuantity = async function updateJobLineItemQuantity(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  qty,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineItemQuantity'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        NewQuantity: parseInt(qty, 10),
      },
    )
  ).data;
};

Touch.prototype.UpdateJobLineStockItemQuantity = async function UpdateJobLineStockItemQuantity(
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  qty,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineStockItemQuantity'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        NewQuantity: parseInt(qty, 10),
      },
    )
  ).data;
};

Touch.prototype.UpdateJobLineItemOption = async function UpdateJobLineItemOption(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  heading,
  value,
  components,
  members,
  parameters,
  text,
  rule,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineItemOption'),
      {
        CustomerId: customerId,
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        OptionHeadingId: heading,
        NewOptionId: value,
        OnlyTheseComponents: components,
        OnlyTheseMembers: members,
        Text: text,
        Parameters: parameters,
        Rule: parseInt(rule, 10),
      },
    ),
  );
};

Touch.prototype.createTradeQuote = async function createTradeQuote(customerId) {
  return this.parseResponseWith400(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/createTradeQuote'),
      {
        CustomerId: customerId,
      },
    ),
  );
};

Touch.prototype.processingGetEarliestDeliveryDate =
  async function processingGetEarliestDeliveryDate(contractId, jobKey) {
    return this.parseResponse(
      await this.authenticatedPost(
        await store.dispatch('sandbox/processingUrl', 'processing/GetEarliestDeliveryDate'),
        {
          ContractId: contractId,
          JobKey: jobKey,
        },
      ),
    );
  };

Touch.prototype.processingForceDefaults = async function processingForceDefaults(
  contractId,
  jobKey,
  itemKey,
  processingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/ForceDefaults'),
      {
        ContractId: contractId,
        JobKey: jobKey,
        ItemKey: itemKey,
        ProcessingLevel: processingLevel,
      },
    ),
  );
};

Touch.prototype.createTradeOrder = async function createTradeOrder(customerId) {
  return this.parseResponseWith400(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/createTradeOrder'),
      {
        CustomerId: customerId,
      },
    ),
  );
};

Touch.prototype.jobLineItem = async function jobLineItem(
  customerId,
  contractId,
  jobKey,
  itemKey,
  processingLevel,
  recalculate = false,
  visualiserImages = false,
  exchangeRate = 1,
) {
  const item = this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/JobLineItem'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: jobKey,
        ItemKey: parseInt(itemKey, 10),
        ProcessingLevel: processingLevel,
        Recalculate: recalculate,
        FormatImagesForVisualiser: visualiserImages,
      },
    ),
  );

  item.price *= exchangeRate;
  item.tax *= exchangeRate;

  if (item.priceBreakdown) {
    item.priceBreakdown = item.priceBreakdown.map((breakdownRow) => ({
      ...breakdownRow,
      discount: breakdownRow.discount * exchangeRate,
      gross: breakdownRow.gross * exchangeRate,
      nett: breakdownRow.nett * exchangeRate,
      rate: breakdownRow.rate * exchangeRate,
      value: breakdownRow.value * exchangeRate,
    }));
  }

  return item;
};

Touch.prototype.processingJob = async function processingJob(
  contractId,
  jobKey,
  recalculate = false,
) {
  const rawJob = this.parseResponse(
    await this.authenticatedPost(await store.dispatch('sandbox/processingUrl', 'processing/Job'), {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      Recalculate: recalculate,
    }),
    () => {
      Sentry.captureEvent({
        message: `Unable to load job: ${contractId}/ ${jobKey}`,
      });
      window.alertBox
        .fire({
          customClass: {
            cancelButton: 'absolute top-0 right-0 p-3',
            confirmButton: 'btn-action m-1',
            denyButton: 'btn m-1',
          },
          title: 'Unable to load contract',
          text: 'We were unable to load this contract from the database, Would to like to re-try?',
          icon: 'question',
          className: 'relative',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'No, Clear my basket',
          confirmButtonText: 'Yes, Retry',
          cancelButtonText: '<i class="fal fa-times"></i>',
        })
        .then(async (result) => {
          if (result.isDismissed) {
            window.location.reload();
            return;
          }

          if (!result.isConfirmed) {
            store.commit('basket/clearBasket');
            await router.push('/');
            return;
          }

          window.location.reload();
        });
    },
  );

  if (rawJob === undefined) {
    throw new Error('Unable to load Job');
  }

  if (rawJob.exchangeRate === null || rawJob.exchangeRate === 0) {
    rawJob.exchangeRate = 1;
  }

  return rawJob;
};

Touch.prototype.processingUpdateJobLineItemNestedDesign =
  async function processingUpdateJobLineItemNestedDesign(
    customerId,
    contractId,
    jobKey,
    itemKey,
    processsingLevel,
    productId,
    designId,
    containerId,
  ) {
    return (
      await this.authenticatedPost(
        await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineItemNestedDesign'),
        {
          CustomerId: customerId,
          ContractId: parseInt(contractId, 10),
          JobKey: parseInt(jobKey, 10),
          ItemKey: parseInt(itemKey, 10),
          ProcessingLevel: processsingLevel,
          ProductId: parseInt(productId, 10),
          StoredDesignId: designId,
          ContainerId: containerId,
        },
      )
    ).data;
  };

Touch.prototype.processingGetChildProducts = async function processingGetChildProducts(
  contractId,
  jobKey,
  itemKey,
  width,
  height,
  customerId,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/GetChildProducts'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobKey, 10),
        ItemKey: parseInt(itemKey, 10),
        TargetWidth: parseInt(width, 10),
        TargetHeight: parseInt(height, 10),
      },
    )
  ).data;
};

Touch.prototype.processingSaveContract = async function processingSaveContract(
  customerId,
  contractId,
  skipAcceptance = false,
  placeTradeOrder = false,
  useLazySaving = true,
) {
  await this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/SaveContract'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        useLazySaving,
        PlaceTradeOrder: placeTradeOrder,
        SkipAcceptance: skipAcceptance,
      },
    ),
    (r) => {
      if (r.data && r.data.message) {
        throw new Error(r.data.message);
      }

      throw new Error('Failed to save contract');
    },
  );
};

Touch.prototype.processingRemoveContract = async function processingRemoveContract(contractId) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/RemoveContract'),
      {
        ContractId: parseInt(contractId, 10),
      },
    )
  ).data;
};

Touch.prototype.processingGet3DModel = async function processingGet3DModel(
  contractId,
  itemKey,
  jobKey,
  customerId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/Get3dModel'),
      {
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobKey, 10),
        ItemKey: parseInt(itemKey, 10),
        CustomerId: parseInt(customerId, 10),
      },
    ),
    (r) => {
      if (r.data && r.data.message) {
        throw new Error(r.data.message);
      }

      throw new Error('Failed to load 3D Model');
    },
  );
};

Touch.prototype.processingUpdateContract = async function processingUpdateContract(
  contractId,
  payload,
) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateContract'),
    {
      ContractId: parseInt(contractId, 10),
      ...payload,
    },
  );
};

Touch.prototype.processingUpdateNote = async function processingUpdateNote(params) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateNote'),
    params,
  );
};

Touch.prototype.processingUpdateFittingType = async function processingUpdateFittingType(
  contractId,
  jobId,
  isFit,
) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateFittingType'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      FittingType: isFit
        ? window.enum.fittingType.SUPPLY_AND_FIT
        : window.enum.fittingType.SUPPLY_ONLY,
    },
  );
};

Touch.prototype.processingUpdateJob = async function processingUpdateJob(
  contractId,
  jobId,
  payload,
) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateJob'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ...payload,
    },
  );
};

Touch.prototype.processingUpdateJobDelivery = async function processingUpdateJobDelivery(
  customerId,
  contractId,
  jobId,
  payload,
) {
  const params = {
    ContractId: parseInt(contractId, 10),
    JobKey: parseInt(jobId, 10),
    ...payload,
  };

  if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
    params.CustomerId = parseInt(customerId, 10);
  }

  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobDelivery'),
    params,
  );
};

Touch.prototype.processingUpdateCustomerPONumber = async function processingUpdateCustomerPONumber(
  contractId,
  poNumber,
) {
  const params = {
    ContractId: parseInt(contractId, 10),
    CustomerPONumber: poNumber,
  };

  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateCustomerPONumber'),
    params,
  );
};

Touch.prototype.processingUpdateExistingExtraItem =
  async function processingUpdateExistingExtraItem(contractId, jobId, itemId, params) {
    return (
      await this.authenticatedPost(
        await store.dispatch('sandbox/processingUrl', 'processing/UpdateExistingExtraItem'),
        {
          ...params,
          ContractId: parseInt(contractId, 10),
          JobKey: parseInt(jobId, 10),
          ItemKey: itemId,
        },
      )
    ).data;
  };

Touch.prototype.processingUpdateNewExtraItem = async function processingUpdateNewExtraItem(
  contractId,
  jobId,
  itemId,
  params,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateNewExtraItem'),
      {
        ...params,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemKey: itemId,
      },
    )
  ).data;
};

Touch.prototype.processingAddExistingExtraItem = async function processingAddExistingExtraItem(
  contractId,
  jobId,
  extraItemId,
  itemId = undefined,
  params = {},
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/AddExistingExtraItem'),
      {
        ...params,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemId: extraItemId,
        LinkToItemKey: itemId,
      },
    ),
    (error) => ({
      status: false,
      message: error.data.message,
    }),
  );
};

Touch.prototype.processingJobDelivery = async function processingJobDelivery(
  customerId,
  contractId,
  jobId,
  monthsAhead = 3,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/JobDelivery'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        MonthsAhead: parseInt(monthsAhead, 10),
      },
    ),
    errorCallback,
    (response) => ({ status: true, ...response.data }),
  );
};

Touch.prototype.processingUpdateJobPriceOverride = async function processingUpdateJobPriceOverride(
  contractId,
  jobId,
  overridePrice,
  overrideFitPrice,
) {
  return this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobPriceOverride'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      OverrideCalculatedPrice: true,
      OverrideCalculatedFittingPrice: true,
      OverridePriceTaxable: parseFloat(overridePrice),
      OverrideFittingPriceTaxable: parseFloat(overrideFitPrice),
      OverrideFittingPriceTaxExempt: 0,
      OverridePriceTaxExempt: 0,
    },
  );
};

Touch.prototype.processingUpdateJobLineItemRequote =
  async function processingUpdateJobLineItemRequote(contractId, jobId, processingLevel, items) {
    return this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateJobLineItemRequote'),
      {
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ProcessingLevel: processingLevel,
        Items: items.map((item) => ({
          Override: true,
          ItemKey: parseInt(item.itemKey, 10),
          Value: parseFloat(item.price),
          TaxExempt: false,
        })),
      },
    );
  };

Touch.prototype.processingReloadContract = async function processingReloadContract(
  customerId,
  contractId,
) {
  return this.parseResponse(
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/ReloadContract'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
      },
    ),
    (error) => {
      throw new Error(error.data.message);
    },
    () => ({
      status: true,
    }),
  );
};

Touch.prototype.processingDeleteJob = async function processingDeleteJob(contractId, jobId) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/DeleteJob'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
    },
  );
};

Touch.prototype.processingDeleteLineItem = async function processingDeleteLineItem(
  contractId,
  jobId,
  lineItemId,
) {
  await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/DeleteJobLineItem'),
    {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: parseInt(lineItemId, 10),
    },
  );
};

Touch.prototype.processingDuplicateItem = async function processingDuplicateItem(
  customerId,
  contractId,
  jobId,
  lineItemId,
) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/DuplicateItem'),
      {
        CustomerId: customerId,
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
        ItemKey: parseInt(lineItemId, 10),
      },
    )
  ).data;
};

Touch.prototype.processingCopyJob = async function processingCopyJob(contractId, jobId) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/CopyJob'),
      {
        ContractId: parseInt(contractId, 10),
        JobKey: parseInt(jobId, 10),
      },
    )
  ).data.jobKey;
};

Touch.prototype.resetJobLineItem = async function resetJobLineItem(
  contractId,
  jobId,
  itemKey,
  processingLevel,
) {
  return this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/ResetJoblineItem'),
    {
      ContractId: Number(contractId),
      JobKey: Number(jobId),
      ItemKey: Number(itemKey),
      ProcessingLevel: Number(processingLevel),
    },
  );
};

Touch.prototype.getEnquiryStatuses = async function getEnquiryStatuses() {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/GetEnquiryStatuses'),
    )
  ).data;
};

Touch.prototype.updateQuoteStatus = async function updateQuoteStatus(contractId, status) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateQuoteStatus'),
      {
        ContractId: Number(contractId),
        Status: Number(status),
      },
    )
  ).data;
};

Touch.prototype.updateEnquiryStatus = async function updateEnquiryStatus(contractId, status) {
  return (
    await this.authenticatedPost(
      await store.dispatch('sandbox/processingUrl', 'processing/UpdateEnquiryStatus'),
      {
        ContractId: Number(contractId),
        Status: Number(status),
      },
    )
  ).data;
};

Touch.prototype.updateDealerEnquiryStatus = async function updateDealerEnquiryStatus(
  dealerNetworkAssignmentId,
  status,
  refusalReason = '',
) {
  return (
    await this.authenticatedPost('contract/UpdateDealerEnquiryStatus', {
      dealerNetworkAssignmentId: Number(dealerNetworkAssignmentId),
      DealerStatus: Number(status),
      RefusalReason: refusalReason,
    })
  ).data;
};

Touch.prototype.getDocsToAccept = async function getDocsToAccept() {
  const response = await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/GetDocsToAccept'),
  );
  return response.data;
};

Touch.prototype.processingProductSelectionStage = async function processingProductSelectionStage(
  selectedStages,
) {
  const response = await this.authenticatedPost(
    await store.dispatch('sandbox/processingUrl', 'processing/ProductSelectionStage'),
    {
      SelectedStages: selectedStages,
    },
  );

  return response.data;
};

Touch.prototype.processingConvertDealerEnquiry = async function processingConvertDealerEnquiry(
  contractId,
  jobIds,
  processingLevel,
) {
  const { data } = await this.authenticatedPost('processing/ConvertDealerEnquiry', {
    ContractId: Number(contractId),
    JobKeys: jobIds.map((jobId) => Number(jobId)),
    ProcessingLevelId: processingLevel,
  });

  return data;
};

Touch.prototype.processingImage = async function processingImage(
  contractId,
  jobKey,
  itemKey,
  imageType,
) {
  const { data } = await this.authenticatedPost('processing/Image', {
    contractId,
    jobKey,
    itemKey,
    imageType,
  });
  return data;
};

Touch.prototype.processingAddStaffToContract = async function processingAddStaffToContract(
  ContractId,
  JobKey,
  Role,
  StaffId,
) {
  return this.parseResponse(
    await this.authenticatedPost('processing/AddStaffToContract', {
      ContractId,
      JobKey,
      Role,
      StaffId,
    }),
  );
};

Touch.prototype.processingGetItemPriceBreakdown = async function processingGetItemPriceBreakdown(
  CustomerId,
  ContractId,
  JobKey,
  ItemKey,
  ProcessingLevel,
) {
  return this.parseResponse(
    await this.authenticatedPost('processing/GetItemPriceBreakdown', {
      CustomerId,
      ContractId,
      JobKey,
      ItemKey,
      ProcessingLevel,
    }),
  );
};

Touch.prototype.UpdateFabricatorJobPriceOverride = async function UpdateFabricatorJobPriceOverride(
  CustomerId, ContractId, JobKey, OverrideCalculatedPrice, OverridePriceExTax
) {
  return this.parseResponse(await this.authenticatedPost('processing/UpdateFabricatorJobPriceOverride', {
    CustomerId, ContractId, JobKey, OverrideCalculatedPrice, OverridePriceExTax
  }), errorCallback, () => successCallback('Save'));
};


Touch.prototype.UpdateFabricatorItemPriceOverride = async function UpdateFabricatorItemPriceOverride(
  CustomerId, ContractId, JobKey, ItemKey, OverrideItemCalculatedPrice, OverrideItemPriceExTax
) {
  return this.parseResponse(await this.authenticatedPost('processing/UpdateFabricatorItemPriceOverride', {
    CustomerId, ContractId, JobKey, ItemKey, OverrideItemCalculatedPrice, OverrideItemPriceExTax
  }), errorCallback, () => successCallback('Save'));
};


Touch.prototype.ApplyDiscountVoucher = async function ApplyDiscountVoucher(discount) {
  return this.parseResponse(await this.authenticatedPost('processing/ApplyDiscountVoucher', discount), errorCallback, () => successCallback('Save'));
};