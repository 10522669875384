<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Branded Mode Subscriptions'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center">
        Branded Mode Subscriptions
      </div>
    </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for customer..." />
    </portal>
    <slot></slot>
    <loading :loading="loading" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-5">
      <div class="bg-white p-3 xs:p-5 border mb-3 flex">
        <div class="ml-auto">
          <strong>{{ licenses.used }}</strong> out of <strong>{{ licenses.max }}</strong> Branded Modes licenses allocated.
        </div>
      </div>
      <div class="bg-white p-3 xs:p-10 border">
        <table class="table-lined xs:table-unstacked _with-hover w-full bg-white p-5">
          <thead>
            <tr>
              <th> Company </th>
              <th> Designer Url </th>
              <th>
                Branded Mode
                <span class="cursor-pointer inline-block relative">
                  <span
                    class="inline-block bg-gray-400 rounded p-1 text-white"
                    @click="branded_mode_dropdown = !branded_mode_dropdown"
                  >
                    <i class="fal fa-filter mx-1"></i>
                    <span v-if="$route.query.brandedMode == enums.brandedMode.ENABLED">
                      Enabled
                    </span>
                    <span v-else-if="$route.query.brandedMode == enums.brandedMode.FABRICATOR_ENABLED">
                      Fabricator Enabled
                    </span>
                    <span v-else-if="$route.query.brandedMode == enums.brandedMode.CUSTOMER_ENABLED">
                      Customer Enabled
                    </span>
                    <i v-if="branded_mode_dropdown" class="fal fa-chevron-up mx-1"></i>
                    <i v-else class="fal fa-chevron-down mx-1"></i>
                  </span>
                  <div v-show="branded_mode_dropdown" class="relative">
                    <div class="absolute bg-white z-10 border border-gray-300 text-xs rounded right-0 mt-1" style="min-width: 200px;">
                      <div class="p-1 border-b border-gray-300 font-medium flex justify-between m-2">
                        <div class="flex flex-col justify-around pr-6 whitespace-no-wrap">Branded Mode</div>
                        <div class="flex flex-col justify-around">
                          <i class="fal fa-times" @click.stop="branded_mode_dropdown = false"></i>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div
                          v-for="status in [enums.brandedMode.ENABLED, enums.brandedMode.FABRICATOR_ENABLED, enums.brandedMode.CUSTOMER_ENABLED]"
                          :key="status.id"
                          class="py-2 px-4 hover:bg-gray-200"
                          @click="toggleBrandedModeFilter(status)"
                        >
                          <span v-if="status == enums.brandedMode.ENABLED">
                            <span class="inline-block rounded-full w-2 h-2 mr-2 bg-green-500"></span>
                            Enabled
                          </span>
                          <span v-else-if="status == enums.brandedMode.FABRICATOR_ENABLED">
                            <span class="inline-block rounded-full w-2 h-2 mr-2 bg-blue-500"></span>
                            Fabricator Enabled
                          </span>
                          <span v-else-if="status == enums.brandedMode.CUSTOMER_ENABLED">
                            <span class="inline-block rounded-full w-2 h-2 mr-2 bg-red-500"></span>
                            Customer Enabled
                          </span>
                          <span v-show="$route.query.brandedMode == status" class="text-green-600 pl-2">
                            <i class="fal fa-check"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in customers"
              :key="customer.id"
            >
              <td>
                <div class="td-title w-24"> Company: </div>
                <div class="td-content">{{ customer.name }}</div>
              </td>
              <td>
                <div>
                  <a v-if="customer.brandedAlias" :href="baseURL + customer.brandedAlias" target="_blank" class="text-blue-600 underline">
                    <div class="td-title w-24"> Designer Url: </div>
                    <div class="td-content">{{ baseURL + customer.brandedAlias }}</div>
                  </a>
                </div>
              </td>
              <td>
                <div class="flex items-center gap-3">
                  <span class="text-xs opacity-50">{{ customer.brandedEnabled ? 'Enabled' : 'Disabled' }}</span>
                  <div
                    class="relative cursor-pointer" 
                    :class="{'pointer-events-none opacity-50': customer.brandedEnabled && !customer.fabricatorBrandedEnabled}"
                    @click="updateBrandedFabricator(customer.customerId, customer.name, !customer.brandedEnabled)"
                  >
                    <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                    <div class="switch-toggle" :class="{ _active: customer.brandedEnabled }"></div>
                  </div>
                  <div v-if="customer.brandedEnabled && !customer.fabricatorBrandedEnabled">
                    <Tooltip :width="200">
                      <span>
                        Activated by customer. The customer would need to cancel branded mode for the fabricator to enable it.
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="customers.length === 0" key="none-found" class="bg-white p-3 xs:p-10">
          <div class="flex justify-between">
            <div>
              No Customers to show
            </div>
            <div v-if="$route.query.search || $route.query.brandedMode">
              <a class="btn-action" @click.stop="setDefaultFilter()">
                Clear Search
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="customers.length > 0" class="mt-3">
        <pagination
          :key="JSON.stringify($route.query)"
          :total_items="total_items"
          :limit="limit"
          @load-feed="loadFeed"
        ></pagination>
      </div>
    </loading>
  </touch-layout>
</template>

<script>

import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import Tooltip from '@/components/shared/Tooltip.vue';

export default {
  components: {
    pagination: Pagination,
    Breadcrumbs,
    SearchBox,
    Tooltip
  },
  data() {
    return {
      customers: [],
      loading: true,
      limit: 20,
      total_items: 0,
      branded_mode_dropdown: false,
      licenses: {
        used: 0,
        max: 0
      }
    };
  },
  computed: {
    baseURL () {
      return window.touch.endpoint.replace('server', 'portal').replace('api', 'launch')
    }
  },
  watch: {
    '$route.query': {
      handler(queryNew, queryOld) {
        this.branded_mode_dropdown = false;
        if (queryNew?.search !== queryOld?.search) {
          this.$router.push({ query: { ...this.$route.query, brandedMode: undefined } }).catch(() => {});
        }
        this.loadFeed();
      },
      immediate: true
    },
  },
  async created () {
    await this.getBrandedFabricatorLimit()
  },
  methods: {
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await window.touch.ListCustomerBrandedDetails({
        Limit: this.limit,
        Offset: offset,
        SearchName: this.$route.query.search,
        BrandedEnabled: this.$route.query.brandedMode ? true : undefined,
        FabricatorBrandedEnabled: this.$route.query.brandedMode == this.enums.brandedMode.FABRICATOR_ENABLED 
          ? true
          : (this.$route.query.brandedMode == this.enums.brandedMode.CUSTOMER_ENABLED ? false : undefined),
        GetTotal: true
      });
      this.customers = response.customerBrandedSummaries;
      this.total_items = response.total;
      this.loading = false;
    },
    toggleBrandedModeFilter(brandedMode) {
      this.$router.push({ query: { ...this.$route.query, brandedMode: this.$route.query.brandedMode == brandedMode ? undefined : brandedMode } }).catch(() => {});
      this.branded_mode_dropdown = false
    },
    async getBrandedFabricatorLimit () {
      const {used, max} = await window.touch.GetBrandedFabricatorLimit() || {}
      if (used || max) {
        this.licenses = { used, max }
      }
    },
    async updateBrandedFabricator (customerId, customerName, brandedMode) {
      const brandedString = brandedMode ? 'enable' : 'disable'
      const html = 
        '<h2 class="text-l text-black">Are you sure you want to <strong><u>' + brandedString + '</u></strong> branded mode for ' + customerName.trim() + '?</h2>'
      this.alertBox()
        .fire({
          html,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            await window.touch.updateBrandedFabricator(customerId, brandedMode)
            await this.getBrandedFabricatorLimit()
            await this.loadFeed()
            this.loading = false;
          }
        })       
    },
    setDefaultFilter() {
      this.$router.replace({ query: undefined }).catch(() => {});
    },
  }
};
</script>