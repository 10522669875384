<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs :routes="[['Dashboard', '/'], ['Basket']]" />
    </template>
    <template #header_page_title> Basket </template>
    <template #header_page_actions>
      <div class="pr-3 md:px-10 flex items-center flex items-center cursor-pointer" @click="isCostsHidden = !isCostsHidden">
        <div class="relative">
          <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
          <div class="switch-toggle" :class="{'_active': !isCostsHidden }"></div>
        </div>
        <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
          <span v-text="!isCostsHidden ? 'Costs On' : 'Costs Off'"></span>
        </div>
      </div>
    </template>
    <Basket
      :customer="customer"
      :can-skip-sop="canSkipSop"
      :show-place-order-button="showPlaceOrderButton"
      :show-save-product-button="showSaveProductButton"
      :is-costs-hidden="isCostsHidden"
      :basket-items="basketItems"
      :basket-main-items="basketMainItems"
      :hide-virtual-home-banner="hideVirtualHomeBanner"
      url=""
      breakdown="Cost"
      @placeOrderProcess="placeOrderProcess"
      @saveQuote="saveQuote"
    >
      <template #pricing="{ basketCurrency, price, quantity, override }">
        <div :class="{'ml-auto': !quantity}">
          <div
            v-if="((override || basketMainItems.some(x => x.override === true)) && !quantity && !$store.state.basket.job.discountApplied)"
            class="line-through opacity-50"
            v-html="formatCurrency(
              basketCurrency,
              $store.state.basket.job.calculatedPriceTaxable + delivery + surveyPrice + fittingPrice
            )"
          ></div>
          <div class="flex gap-2">
            <div v-html="formatCurrency(basketCurrency, price)"></div>
            <span v-if="quantity > 1"> (<span class="font-medium" v-html="formatCurrency(basketCurrency, price / quantity)"></span> each) </span>  
          </div>
        </div>
      </template>
    </Basket>
  </touch-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Basket from '@/components/shared/basket/Basket.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import currencyHelper from '../../mixins/currencyHelper';

export default {
  components: {
    Breadcrumbs,
    Basket,
  },
  mixins: [
    currencyHelper
  ],
  data() {
    return {
      customer: undefined,
      isCostsHidden: true,
    };
  },
  computed: {
    ...mapState('basket', {
      basketItems: 'basket',
      buttonName: 'buttonName',
      job: 'job'
    }),
    ...mapGetters({
      fittingPrice: 'basket/fittingPrice',
      surveyPrice: 'basket/surveyPrice',
      delivery: 'basket/delivery',
    }),
    basketMainItems() {
      return this.basketItems.filter(
        (item) => item.inputType === window.enum.inputType.FENESTRATION,
      );
    },
    canSkipSop() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showPlaceOrderButton() {
      return this.customer?.customer?.skipOrderAcceptance;
    },
    showSaveProductButton() {
      return !this.customer?.customer?.skipOrderAcceptance;
    },
    hideVirtualHomeBanner() {
      return (
        this.basketItems.filter((x) => x.omitFromVisualiser).length === this.basketItems.length ||
        !this.branded_mode_enabled || 
        this.basketMainItems.length === 0
      );
    },
  },
  async mounted() {
    this.customer = await this.customerGet();
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    async placeOrderProcess() {
      const portalBypassSopUrl = '/checkout';
      if (this.$store.state.basket.skipSop) {
        await this.routerPush(portalBypassSopUrl);
      } else {
        await this.routerPush('/orders');
      }
    },
    async saveQuote() {
      await this.routerPush('/quotes');
    },
  },
};
</script>
