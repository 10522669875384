import moment from 'moment';

export default {
  namespaced: true,
  state: {
    authToken: undefined,
    authTokenExpiry: undefined,
    productInfo: undefined,
    demoMode: false,
    companyName: undefined,
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    email: undefined,
    username: undefined,
    image: undefined,
    loggedIn: false,
    processingLevel: 99,
    enquiryProcessingLevel: 1,
    usingBrandedModeToken: false,
    isDealerNetworkMode: false,
    staffLogin: false,
    admin: false,
    isDealerNetworkModeAvailable: false,
    superuser: false,
    isBranchManagerAvailable: false,
    isBranchManager: 0,
    accountSettings: {},
    login_url: '/login'
  },
  mutations: {
    setAuth(state, { property, token, tokenExpiry }) {
      state.authToken = state.authToken || {};
      state.authTokenExpiry = state.authTokenExpiry || {};
      state.authToken[property] = token;
      state.authTokenExpiry[property] = tokenExpiry;
      window.touch.setLogin(state.authToken, state.authTokenExpiry, property);
    },
    setIsBranchManager(state, isBranchManager) {
      state.isBranchManager = isBranchManager;
    },
    SET_PRODUCT_INFO(state, productInfo) {
      state.productInfo = productInfo;
    },
    login(state, user) {
      state.loggedIn = true;
      state.id = user.id;
      state.companyName = user.companyName;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.mobile = user.mobile;
      state.email = user.email;
      state.username = user.username;
      state.image = user.image;
      state.staffLogin = user.staffLogin;
      state.admin = user.admin;
      if (user.superuser) {
        state.superuser = user.superuser;
      }
      if (user.isDemo) {
        state.demoMode = user.isDemo;
      } else {
        state.demoMode = false;
      }
    },
    setAccountSettings(state, accountSettings) {
      state.accountSettings = accountSettings;
    },
    setProcessingLevel(state, processingLevel) {
      state.processingLevel = parseInt(processingLevel, 10);
    },
    setBrandedMode(state, mode) {
      state.usingBrandedModeToken = mode;
    },
    setDealerNetworkMode(state, mode) {
      state.isDealerNetworkMode = mode;
    },
    setDealerNetworkModeAvailable(state, available) {
      state.isDealerNetworkModeAvailable = available;
    },
    setBranchManagerAvailable(state, available) {
      state.isBranchManagerAvailable = available;
    },
  },
  actions: {
    async setAuthToken({ commit }, { property, token }) {
      commit('setAuthToken', { property, token });
    },
    async setAuthTokenExpiry({ commit }, { property, tokenExpiry }) {
      commit('setAuthTokenExpiry', { property, tokenExpiry });
    },
    async getProductInfo({ commit, state }) {
      if (state.productInfo === undefined) {
        commit('SET_PRODUCT_INFO', await window.touch.authAuthorisedProducts());
      }

      return state.productInfo.products;
    },
    async hasModule({ dispatch }, { productName, moduleName }) {
      const productInfo = await dispatch('getProductInfo');
      return (
        productInfo.find(
          (product) =>
            product.name === productName &&
            product.modules.find((module) => module.name === moduleName) !== undefined,
        ) !== undefined
      );
    },
    async GetJWT({ commit }, property) {
      const response = await window.touch.authGetJWT();
      commit('setAuth', {
        property,
        token: response.data.token,
        tokenExpiry: response.data.expiresAt,
      });
    },
    async setProcessingLevel({ commit }) {
      commit('setProcessingLevel', await window.touch.processingGetProcessingLevel());
    },
    async setVendorLogin(
      { commit, dispatch },
      { isDemo, isDealerNetwork, property, token, tokenExpiry },
    ) {
      commit('setAuth', { property, token, tokenExpiry });
      commit('setProcessingLevel', await window.touch.processingGetProcessingLevel());
      commit('setBrandedMode', true);
      commit('setDealerNetworkMode', isDealerNetwork);
      commit('login', {
        isDemo,
      });
      dispatch('style/refresh', {}, { root: true });
    },
    setEndpoint(context, endpoint) {
      const hasTrailingSlash = endpoint.charAt(endpoint.length - 1) === '/';
      window.touch.setEndpoint(hasTrailingSlash ? endpoint : `${endpoint}/`);
    },
    setImageBaseURL(context, url) {
      window.touch.setImageBaseURL(url);
    },
    async setLogin({ commit }) {
      const response = await window.touch.getUserDetails();
      commit('login', {
        id: response.userId,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.userImage,
        email: response.email,
        staffLogin: response.isStaff,
        admin: response.isAdmin,
      });
    },
    async validateLogin({ state, commit }, property) {
      if (
        !state.authToken?.[property] ||
        state.authTokenExpiry?.[property] === undefined ||
        moment(state.authTokenExpiry?.[property]).isBefore() ||
        !state.loggedIn
      ) {
        return false;
      }

      if (property === 'public') {
        commit('setBrandedMode', true);
      } else {
        commit('setBrandedMode', false);
      }

      window.touch.setLogin(state.authToken, state.authTokenExpiry, property);

      return true;
    },
    async attemptPortalLogin({ rootState, commit, dispatch }, { password, username }) {
      const response = await window.touch.logincustomer(username, password);

      if (response === false) {
        commit('reset', ['basket', 'style', 'feature', 'designer.isQuickTip'], { root: true });
        return false;
      }
      commit('setAuth', {
        property: 'customer',
        token: response.token,
        tokenExpiry: response.tokenExpiry,
      });

      window.logoutPush = false;

      dispatch('style/refresh', {}, { root: true });
      commit('login', {
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: username,
        username,
        staffLogin: false,
        admin: response.admin,
        isDemo: response.isDemo,
      });

      const { data } = await window.touch.isProductAvailable(28);
      dispatch('basket/setIsPaymentGateway', data.available, { root: true });

      await dispatch('user/setCustomer', undefined, { root: true });
      await dispatch(
        'basket/setSalesSectorType',
        rootState.user.customer.customer.salesSectorType,
        { root: true },
      );

      commit('setAccountSettings', await window.touch.getAccountSettings());
      commit('setProcessingLevel', await window.touch.processingGetProcessingLevel());
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );
      commit('setDealerNetworkModeAvailable', dealerNetworkModeAvailable.data.available);

      window.gtag('event', 'Login', {
        fabricator: rootState.style.fabricator.name,
        company: response.companyName,
        installation: 'portal',
        email: username,
      });

      return true;
    },
    async attemptSuperUserLogin({ rootState, commit, dispatch }, { password, username, email }) {
      const response = await window.touch.authLoginSuperUser(username, password, email);

      if (response === false) {
        commit(
          'reset',
          ['basket', 'touch.isDealerNetworkPartnerOnly', 'style', 'feature', 'designer.isQuickTip'],
          { root: true },
        );
        return false;
      }

      const property = window.VUE_APP_INSTALLATION_TYPE === 'business' ? 'staff' : 'customer';

      commit('setAuth', { property, token: response.token, tokenExpiry: response.tokenExpiry });

      dispatch('style/refresh', {}, { root: true });
      commit('login', {
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: email,
        username,
        staffLogin: window.VUE_APP_INSTALLATION_TYPE === 'business',
        admin: response.admin,
        superuser: response.superuser,
      });

      const { data } = await window.touch.isProductAvailable(28);
      dispatch('basket/setIsPaymentGateway', data.available, { root: true });

      if (window.VUE_APP_INSTALLATION_TYPE === 'business') {
        await dispatch('user/setCustomer', await window.touch.staffGetOrganisationCustomerId(), {
          root: true,
        });
      } else {
        await dispatch('user/setCustomer', undefined, { root: true });
        await dispatch(
          'basket/setSalesSectorType',
          rootState.user.customer?.customer?.salesSectorType,
          { root: true },
        );
      }
      if (!rootState.user.customer) {
        commit(
          'reset',
          ['basket', 'touch.isDealerNetworkPartnerOnly', 'style', 'feature', 'designer.isQuickTip'],
          { root: true },
        );
        return false
      }
      commit('setAccountSettings', await window.touch.getAccountSettings());
      commit('setProcessingLevel', await window.touch.processingGetProcessingLevel());
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );
      commit('setDealerNetworkModeAvailable', dealerNetworkModeAvailable.data.available);
      const branchManagerAvailable = await window.touch.isProductAvailable(
        window.enum.productType.BRANCHES,
      );
      commit('setBranchManagerAvailable', branchManagerAvailable.data.available);

      window.gtag('event', 'Login', {
        fabricator: rootState.style.fabricator.name,
        company: response.companyName,
        installation: window.VUE_APP_INSTALLATION_TYPE,
        email: username,
      });

      return true;
    },
    async attemptBusinessLogin({ commit, dispatch, rootState }, { password, username }) {
      const response = await window.touch.loginstaff(username, password);

      if (response === false) {
        commit(
          'reset',
          ['basket', 'touch.isDealerNetworkPartnerOnly', 'style', 'feature', 'designer.isQuickTip'],
          { root: true },
        );
        return false;
      }
      window.logoutPush = false;

      commit('setAuth', {
        property: 'staff',
        token: response.token,
        tokenExpiry: response.tokenExpiry,
      });

      dispatch('style/refresh', {}, { root: true });

      commit('login', {
        id: response.id,
        companyName: response.companyName,
        firstName: response.firstName,
        lastName: response.lastName,
        image: response.image,
        mobile: response.mobile,
        email: username,
        username,
        staffLogin: true,
        admin: response.admin,
      });

      const { data } = await window.touch.isProductAvailable(28);
      dispatch('basket/setIsPaymentGateway', data.available, { root: true });

      await dispatch('user/setCustomer', await window.touch.staffGetOrganisationCustomerId(), {
        root: true,
      });
      const isBranchManager = rootState.user.customer.users.filter(
        (usr) => usr.id === response.id,
      )[0].branchCompanyId;
      commit('setIsBranchManager', isBranchManager);

      commit('setAccountSettings', await window.touch.getAccountSettings());
      commit('setProcessingLevel', await window.touch.processingGetProcessingLevel());
      const dealerNetworkModeAvailable = await window.touch.isProductAvailable(
        window.enum.productType.DEALER,
      );
      commit('setDealerNetworkModeAvailable', dealerNetworkModeAvailable.data.available);
      const branchManagerAvailable = await window.touch.isProductAvailable(
        window.enum.productType.BRANCHES,
      );
      commit('setBranchManagerAvailable', branchManagerAvailable.data.available);

      window.gtag('event', 'Login', {
        fabricator: rootState.style.fabricator.name,
        company: response.companyName,
        installation: 'admin',
        email: username,
      });

      return true;
    },
    async requestPasswordReset(context, username) {
      return window.touch.requestPasswordReset(username).catch((error) => {
        // @TODO need checks here on why did we error, and report errors to sentry
        //       If the error is token not valid then it needs to be a different status
        //       code, such as 401? or 404? - only then can we not send to Sentry.
        const resp = {
          error: true,
          data: error,
        };
        return resp;
      });
    },
    async resetPassword(context, { token, newPassword }) {
      return window.touch.resetPassword(token, newPassword);
    },
    async checkPasswordResetToken(context, token) {
      return (await window.touch.checkPasswordResetToken(token)).data;
    },
    async getCompanyWebSettings() {
      return window.touch.authGetCompanyWebSettings();
    },
    async updateCompanyWebSettings(context, { key, value }) {
      return (await window.touch.authUpdateCompanyWebSettings(key, value)).data;
    },
    async getOrganisationWebSetting(context, searchKey) {
      const ret = await window.touch.authGetOrganisationWebSetting(searchKey);
      return ret.companyWebSettings[0];
    },
    async isDesignerAvailable(context, ecommerceAccountAlias) {
      const resp = await window.touch.isDesignerAvailable(ecommerceAccountAlias);
      return resp;
    },
    async isProductAvailable(context, product) {
      return window.touch.isProductAvailable(product);
    },
  },
  getters: {
    user(state) {
      return {
        id: state.id,
        companyName: state.companyName,
        firstName: state.firstName,
        lastName: state.lastName,
        image: state.image,
        email: state.email,
        mobile: state.mobile,
        staffLogin: state.staffLogin,
      };
    },
  },
};
